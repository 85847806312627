<template>
  <q-slide-item left-color="blue" @left="onLeft">
    <template v-if="message.read_at === null" v-slot:left>
      <q-icon name="done" />
    </template>
    <q-item
      bordered
      clickable
      class="q-pa-md q-ma-sm"
      @click="setItemAsReady"
      :to="routeName() || void 1"
      :class="itemClass + ` ${!!message.read_at ? 'notify-ready' : ''}`"
      style="border: 1px solid rgb(184, 184, 184); border-radius: 10px;"
    >
      <q-item-section v-if="showIcon" avatar>
        <q-icon
          v-if="message.read_at"
          name="done_all"
          class="block"
          color="green"
          style="font-size: 25pt;"
        />
        <q-icon v-else name="campaign" class="block" style="font-size: 25pt;" />
      </q-item-section>
      <div class="row">
        <div class="col-12">
          <div v-if="!isMenu">
            <q-item-label
              class="text-cs-title text-uppercase"
              v-html="message.notify_title"
            />
            <p
              v-if="expandMessage"
              class="full-width text-cs-subtitle text-justify q-pt-sm"
              v-html="getContentFormatted()"
            />

            <p
              v-else
              class="full-width text-cs-subtitle q-pt-sm text-justify"
              v-html="getContentFormatted()"
            />
          </div>
          <div v-else>
            <q-item-label class="text-cs-title text-uppercase text-justify">
              <q-icon
                name="campaign"
                style="font-size: 20pt;"
                class="q-mb-xs"
                color="blue"
              />
              {{ message.notify_title }}
            </q-item-label>
            <q-item-label
              side
              class="text-cs-subtitle q-pt-sm text-justify"
              v-html="getContentMenuFormatted(message)"
            />
          </div>
          <div class="text-cs-subtitle q-mt-md" v-html="dateFormatted" />
        </div>
      </div>
    </q-item>
  </q-slide-item>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError, notifyInfo } from "@/shared/helpers/notify";
import { useQuasar } from "quasar";
import moment from "moment";

const { useActions } = createNamespacedHelpers("notifications");

export default {
  name: "MessageItem",

  props: {
    itemClass: {
      type: String,
    },
    message: {
      type: Object,
      required: true,
    },
    showIcon: {
      type: Boolean,
      required: true,
    },
    expandMessage: {
      type: Boolean,
      required: true,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const $q = useQuasar();

    let startTime = moment(props.message.start_date);
    const dateFormatted = startTime.isSame(new Date(), "day")
      ? startTime.calendar()
      : startTime.format("LLL");

    const { putNotificationAsReadAction } = useActions([
      "putNotificationAsReadAction",
    ]);

    const setItemAsReady = (action = null) => {
      if (props.isMenu) return;
      if (props.message.read_at) return action ? action() : null;

      putNotificationAsReadAction(props.message)
        .then(() => notifyInfo("notifications.readySuccess"))
        .catch(() => notifyError("notifications.readyError"))
        .finally(() => {
          if (action) action();
        });
    };

    const getContentFormatted = () => {
        const lengthLimit = $q.screen.width < 800 ? 80 : 300;

        if (typeof props.message.content === "object") {
          if (
            props.message.content &&
            Object.prototype.hasOwnProperty.call(props.message.content, "body")
          )
            return props.message.content.body;
          else return null;
        } else
          return props.expandMessage
            ? props.message.content
            : props.message.content.length > lengthLimit
            ? props.message.content.substring(0, lengthLimit) + "..."
            : props.message.content;
      },
      getContentMenuFormatted = () => {
        const lengthLimit = $q.screen.width < 800 ? 50 : 80;

        return props.expandMessage
          ? props.message.content
          : props.message.content.length > lengthLimit
          ? props.message.content.substring(0, lengthLimit) + "..."
          : props.message.content;
      },
      routeName = () => ({ name: "notifications" });

    const getNotifyStyle = (message) => {
      if (message.config) {
        const config = JSON.parse(JSON.parse(message.config));

        return {
          "background-color": config.bg_color,
          color: config.text_color,
        };
      }
      return {};
    };

    return {
      dateFormatted,
      routeName,
      getNotifyStyle,
      setItemAsReady,
      getContentFormatted,
      getContentMenuFormatted,
      onLeft({ reset }) {
        setItemAsReady(reset);
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.text-cs-subtitle {
  font-weight: 300 !important;
}
.notify-ready {
  opacity: 0.8;
}
</style>

<template>
  <q-item class="bg-grey-1 q-pa-md">
    <q-item-section v-if="showIcon" avatar>
      <q-skeleton type="circle" />
    </q-item-section>
    <q-item-section class="full-width">
      <q-item-label class="text-cs-title">
        <q-skeleton width="100px" />
      </q-item-label>
      <q-item-label class="full-width  text-cs-subtitle"
        ><q-skeleton
      /></q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: "MessageItemSkeleton",

  props: {
    showIcon: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <message-item-empty v-if="messages.length === 0 && !showSkeleton" />
  <message-item-skeleton
    v-else-if="showSkeleton"
    v-for="key in 3"
    :key="`skeleton-${key}`"
    :show-icon="showIcon"
  />
  <message-item
    v-else
    v-for="msg in messages"
    :key="msg.id"
    :message="msg"
    :isMenu="isMenu"
    :show-icon="showIcon"
    :item-class="itemClass"
    @click="itemClicked(msg.id)"
    :expand-message="msg.id === labelExpanded"
  />
</template>

<script>
import MessageItemSkeleton from "@/modules/main/pages/notifications/components/messageItemSkeleton";
import MessageItemEmpty from "@/modules/main/pages/notifications/components/messageItemEmpty";
import MessageItem from "@/modules/main/pages/notifications/components/messageItem";
import { ref } from "vue";

export default {
  name: "Messages",

  components: { MessageItemSkeleton, MessageItemEmpty, MessageItem },

  props: {
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    itemClass: {
      type: String,
    },
    messages: {
      type: Array,
      required: true,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const labelExpanded = ref(null);

    const itemClicked = (keyId) => {
      if (props.isMenu) return;
      if (labelExpanded.value === keyId) {
        labelExpanded.value = null;
      } else {
        labelExpanded.value = keyId;
      }
    };

    return {
      itemClicked,
      labelExpanded,
    };
  },
};
</script>

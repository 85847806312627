<template>
  <div class="notification bg-grey-1">
    <div class="notification-item no-wrap bg- text-grey-7  row justify-between">
      <div class="icon row justify-center flex-center">
        <q-icon size="2.5em" name="outbox" class="q-pl-lg" />
      </div>
      <div
        class="notification-item-info q-ma-lg width-100 row flex-center justify-between"
      >
        {{ $t("notifications.empty") }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification {
  font-size: 1.1em;
  &-item-info > div {
    width: 100%;
  }
}
</style>
